import { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { Id, ToastContainer, toast } from "react-toastify";

import { useAccountEffect } from "wagmi";

import { UpdateCertsToast, useCerts } from "entities/cert";
import { useSyncSession } from "shared/stores";
import { CloseButton } from "shared/ui/toast";

import { useLocalStorageMigrations } from "./use-localstorage-migrations";

export const Root = () => {
  useSyncSession();
  useLocalStorageMigrations();

  const toastRef = useRef<Id | null>(null);
  const { hasUpdates } = useCerts();

  const shouldInitRef = useRef(true);

  useAccountEffect({
    onDisconnect: () => {
      shouldInitRef.current = false;
    },
  });

  useEffect(() => {
    if (!hasUpdates || toastRef.current) return;

    toastRef.current = toast(
      <UpdateCertsToast
        onUpdate={() => {
          toastRef.current && toast.dismiss(toastRef.current);
          toastRef.current = null;
        }}
      />,
      {
        autoClose: false,
        closeButton: false,
        onClose: () => {
          toastRef.current = null;
        },
      }
    );
  }, [hasUpdates]);

  return (
    <>
      <Outlet />
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position="bottom-right"
      />
    </>
  );
};
